html,
body {
  height: 100% !important;
  margin: 0;
  padding: 0;
}

.none {
  display: none;
  visibility: hidden;
}

.body-no-sroll {
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch !important;
}

#root {
  height: 100% !important;
}

.menuListWrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  background-color: #1a1a1a;
}

.menuListWrapper::-webkit-scrollbar {
  width: 5px;
  background-color: #1a1a1a;
}

.menuListWrapper::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #b3b3b3;
}

.coreLayout-wrapper {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.coreLayout-logoImg {
  display: block;
  width: 100px;
  height: auto;
  animation: coreLayout-logoImg 3.5s linear infinite;
}

@keyframes coreLayout-logoImg {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  textarea,
  select:focus,
  textarea:focus,
  input:focus {
    font-size: 16px !important;
  }
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  src: url('./assets/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  src: url('./assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 600;
  src: url('./assets/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
}

/*override global styles*/
.swal2-container {
  z-index: 999999999 !important;
}

.k-pdf-export .pdf-tooltip {
  display: none;
}

img {
  max-width: 100%;
}
